import React, { useState } from "react";
import Seo from "../components/seo.js";
import Layout from "../components/layout";
import "../styles/ingredients.scss";
import IngredientsCircleGraph from "./ingredientsCircleGraph";
import IngredientsVitamins from "./ingredientsVitamins";

function Ingredients() {
  const [infoModal, setInfoModal] = useState("");
  const [showModal, setShowModal] = useState("hideInfoModal");

  return (
    <Layout headOneOfPage="A Moringa tápanyagok: antioxidánsok, vitaminok, esszenciális aminosavak, ásványi anyagok, nyomelemek">
      <Seo title="A Moringa tápanyagai" lang="hu" />
      <div id="container">
        <aside id={showModal}>{infoModal}</aside>
        <main>
          <header>
            <h2>
              A MORINGA
              <span className="highlighted">TÁPANYAGAI</span>
            </h2>
            <p className="ingredientsTitleText">
              A Moringa Oleifera a földön fellelhető növények között a
              legnagyobb tápanyagösszetételű.
            </p>
          </header>

          <section>
            <IngredientsCircleGraph
              setInfoModal={setInfoModal}
              setShowModal={setShowModal}
            />
          </section>
          <section>
            <IngredientsVitamins />
          </section>
          <section className="mainText">
            <article>
              <h2>
                A <b>Moringa Oleifera fogyasztása</b>
              </h2>
              <p>
                A <b>Moringa fa leveleit</b> Indiában Ázsia egyes országaiban és
                nyugat Afrikában rendszeresen fogyasztják az egészségre
                gyakorolt jótékony hatásai miatt. Az indiai ayurvédikus orvoslás
                több, mint 300 féle betegséget kezelt vele. A Moringa leveleken
                végzett vizsgálatok azt mutatják, hogy rengeteg
                nélkülözhetetlen, betegségmegelőző tápanyagot tartalmaznak és
                minden korosztály számára értékes tápanyagforrás lehet,
                különösen olyan helyzetekben, ha alultápláltságról van szó.
              </p>
            </article>
            <article>
              <h2>
                A <b>Moringa tápanyagok</b>
              </h2>
              <p>
                A Moringa fa minden része ehető. A számos makro- és
                mikro-tápanyag mellett, <b>ABCE vitaminokat</b>, esszenciális
                aminosavakat, valamint egyéb bioaktív anyagokat, pl. rengeteg
                antioxidánst is tartalmaz. Az összes{" "}
                <b>esszenciális aminosav</b> is megtalálható a Moringa
                levelekben, ami növények között szokatlan. A szárított Moringa
                levelek még nagyobb mennyiségben tartalmazzák ezeket a
                tápanyagokat, ezért készítenek belőle <b>Moringa teát</b> vagy{" "}
                <b>Moringa smoothiet</b> is. A C vitamin egyedül a kivétel,
                amely a napfény hatására bomlásnak indul, ezért a szárított
                levelekben kevesebb van belőle. A Moringa levele rendkívül kevés
                kalóriát tartalmaz, így fogyókúra esetén is érdemes fogyasztani,
                hiszen segít pótolni a fogyókúra miatt bekövetkező esetleges
                tápanyaghiányt. A levelek tápanyagtartalma függ a termés
                földrajzi helyétől, a talaj minőségétől, valamint a termesztés
                módjától.
              </p>
            </article>
            <article>
              <h2>
                <b>Moringa vitaminok</b>
              </h2>
              <p>
                A <b>Moringa szárított levele</b> nagy mennyiségben tartalmaz{" "}
                <b>ABE vitaminokat</b>,. Az A vitamin a látásban jétszik nagyon
                fontos szerepet, a B vitaminok az idegrendszerre hatnak, az E
                vitamin pedig erős antioxidáns. Kisebb mennyiségben{" "}
                <b>C vitamint</b> is tartalmaznak a levelek, így annak
                pótlásában is segítséget nyújt.
              </p>
            </article>
            <article>
              <h2>
                <b>Moringa ásványi anyagok</b>
              </h2>
              <p>
                A <b>Moringa Oleifera levél</b> nagyon gazdag bizonyos{" "}
                <b>ásványi anyagokban</b>, így sok kalcium, magnézium, kálium,
                vas, cink, mangán, réz található a levelekben, valamint olyan
                fontos nyomelemek, mint a bór vagy a szelén.
              </p>
            </article>
            <article>
              <h2>
                <b>Moringa és a zsírsavak</b>
              </h2>
              <p>
                Összesen 17 féle zsírsavat mértek a Moringa levelekben,
                amelyekre szintén igaz, hogy a szárított levelekben, így pl. a
                Moringa teában is nagyobb mennyiségben vannak jelen. Ezek között
                6 telítetlen zsírsav, így pl. az alfa-linolénsav, amely a
                zsírsav mennyiség közel 50%-a. Ez a többszörösen telítetlen
                Omega-3 zsírsav különösen fontos szereet játszik a testünkben.
                Többek között a vérnyomás csökkentésben is segít.
              </p>
            </article>
            <article>
              <h2>
                <b>Moringa és a fehérjék</b>
              </h2>
              <p>
                A fehérjék fogyasztása kimagaslóan fontos az ember számára.
                Egyrészt a testünkben folyamatosan bomló fehérjék pótlása miatt,
                másrészt mert minden sejtünk alkotóeleme. A fehérjék aminosav
                molekulákból állnak, amelyek közül az esszenciális aminosavakat
                csak táplálkozással tudjuk bejuttatni a testünkbe. Ezért a{" "}
                <b>Moringa</b> fontos szerepet játszhat az <b>aminosavak</b>{" "}
                pótlásában, hiszen 18 féle esszenciális aminosavat is tartalmaz.
              </p>
            </article>
          </section>
        </main>
      </div>
    </Layout>
  );
}

export default Ingredients;
